export const appTypes = [

    // {
    //     color: "rgb(233,219,162)",
    //     title: "Correspondence",
    //     value: 10,
    //     appType: 'CORRESPONDENCE',
    //     appBelongsTo: 'FTC'
    // },




    // {
    //     color: "rgb(234,130,97)",
    //     title: "Request for Information or Opinion",
    //     value: 10,
    //     appType: 'REQUEST_FOR_INFORMATION_OPINION',
    //     appBelongsTo: 'FTC'
    // },

    {
        color: "rgb(234,130,97)",
        title: "Request for Information",
        value: 10,
        appType: 'REQUEST_FOR_INFORMATION',
        appBelongsTo: 'FTC',
        image: '/cads_images/information_3.webp'
    },



    {
        color: "rgb(148,194,200)",
        title: "Request for Authorization",
        value: 10,
        appType: 'REQUEST_FOR_AUTHORIZATION',
        appBelongsTo: 'FTC',
        image: '/cads_images/authorization_3.webp'
    },

    {
        color: "rgb(233,219,162)",
        title: "File a Consumer Complaint",
        value: 12,
        appType: 'CONSUMER_COMPLAINTS',
        appBelongsTo: 'CAC',
        image: '/cads_images/consumer_complaints_4.webp'
    },
    {
        color: "rgb(234,130,97)",
        title: "File a Competition Complaint",
        value: 12,
        appType: 'COMPETITION_COMPLAINTS',
        appBelongsTo: 'FTC',
        image: '/cads_images/competition_complaint_2.webp'
    },


    {
        color: "rgb(233,219,162)",
        title: "File for Mergers & Acquisitions",
        value: 10,
        appType: 'REQUEST_FOR_MERGER_ACQUISITIONS',
        appBelongsTo: 'FTC',
        image: '/cads_images/mergeracquisitions.webp'
    },
    {
        color: "rgb(148,194,200)",
        title: "Request for Advice",
        appType: 'CONSUMER_REQUEST_FOR_ADVICE',
        value: 10,
        appBelongsTo: 'CAC',
        image: '/cads_images/advice.webp'
    },
    {
        color: "rgb(234,130,97)",
        title: "Advocacy",
        value: 10,
        appType: 'REQUEST_FOR_ADVOCACY',
        appBelongsTo: 'FTC',
        image: '/cads_images/advocacy.webp'
    },
    {
        color: "rgb(234,130,97)",
        title: "Request for Opinion",
        value: 10,
        appType: 'REQUEST_FOR_OPINION',
        appBelongsTo: 'FTC',
        image: '/cads_images/opinion_3.webp'
    },  
];