import React from 'react'
import { appTypes } from '../config/app-types';
import { useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string';
import ApplicationType from '../components/ApplicationType'
import FooterContainer from './FooterContainer';
import _ from 'lodash';

export default function Home(props) {
    const history = useHistory();

    const params = useParams();
    let data = appTypes;
    let queryParams = queryString.parse(props.location.search)

    // if (queryParams.route) {
        // localStorage.setItem('route', queryParams.route);
    // } else {
        // queryParams.route = localStorage.getItem('route') ?? "ftc";
    // }

    data = _.filter(appTypes, (data) => data.appBelongsTo === queryParams.route?.toUpperCase()) || [];

    if (data.length === 0) {
        data = appTypes;
    }

    if(!queryParams?.route) {

        return (
            <div className="no-gutters">


           <div className="d-flex m-5 flex-row justify-content-around" style={{gap: '2rem'}}>

                <div onClick={() => {
                    localStorage.setItem('route', 'ftc');
                    history.push('/home?route=ftc');
                    window.location.reload();
                }} className={`application-type-container ftc_app_container d-flex flex-column justify-content-between`} style={{height: '450px', width: '31%', backgroundColor: '#ffffff', borderRadius: '8px', overflow: 'hidden'}}>
                        <div style={{height: '80%', backgroundColor: '#ffffff', width: '100%', backgroundImage: 'url(/cads_images/ftc.webp)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}} />
                        <div className="text-center d-flex align-items-center justify-content-center" style={{backgroundColor: '#336699', color: 'white', height: '20%', width: '100%', fontSize: '2.2rem', fontWeight: '500'}}>Fair Trading Commission</div>
                </div>

                <div onClick={() => {
                    localStorage.setItem('route', 'cac');
                    history.push('/home?route=cac')
                    window.location.reload();
                }}  className={`application-type-container ftc_app_container d-flex flex-column justify-content-between`} style={{height: '450px', width: '31%', backgroundColor: '#ffffff', borderRadius: '8px', overflow: 'hidden'}}>
                        <div style={{height: '80%', backgroundColor: '#ffffff', width: '100%', backgroundImage: 'url(/cads_images/cac.webp)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}} />
                        <div className="text-center d-flex align-items-center justify-content-center" style={{backgroundColor: '#336699', color: 'white', height: '20%', width: '100%', fontSize: '2.2rem', fontWeight: '500'}}>Consumers Affairs Commission</div>
                </div>
           </div>

            <FooterContainer></FooterContainer>

        </div>
        )
    }

    return (
        <div className="no-gutters">

            {/* <div className="col-sm-2">

                <SideBar links={sidebarLinks}></SideBar>
            </div> */}


           <div className="row">
           {
                (queryParams.route === 'cac') &&
                <div className={`d-flex m-4 flex-row justify-content-around `} style={{ width: '100%' }}>
                    <ApplicationType app={data[0]} />
                    <ApplicationType app={data[1]} />
                </div>
            }


            {
                (queryParams.route === 'ftc') &&
                <div className={`d-flex m-4 flex-row justify-content-around `} style={{ width: '100%' }}>
                    <ApplicationType app={data[0]} />
                    <ApplicationType app={data[1]} />
                    <ApplicationType app={data[2]} />
                </div>
            }

            {
                (queryParams.route === 'ftc') &&
                <div className={`d-flex m-4 flex-row justify-content-around `} style={{ width: '100%' }}>
                    <ApplicationType app={data[3]} />
                    <ApplicationType app={data[4]} />
                    <ApplicationType app={data[5]} />
                </div>
            }
           </div>


            {/* <div className="chart-container">
                    <PieChart
                        onClick={async (e, segmentIndex) => {
                            console.log(segmentIndex);

                            const details = APP_SUBMISSION_CONFIG[data[segmentIndex].appType];
                            let transNumber = '';
                            if(details.isLoginRequired){
                                if(!localStorage.getItem('token')){
                                    history.push('/login');
                                    return;
                                }

                                transNumber = await getUniqueTransNumber(data[segmentIndex].appType);
                                
                            }else{
                                transNumber = await getUniqueTransNumber(data[segmentIndex].appType);
                            }
                            
                            store.dispatch({
                                type: SET_CURRENT_TRANSACTION,
                                temp_trans_id: transNumber ? transNumber.uniqueID : '',
                                appType: data[segmentIndex].appType,
                                transaction: {},
                                submitted_transaction:null
                            });
                            
                            if (!!details)
                                history.push(`${details.steps[0].currentStep}`);
                        }}
                        animate
                        animationDuration={250}
                        animationEasing="ease-out"
                        center={[90, 100]}
                        data={data}
                        lengthAngle={360}
                        lineWidth={70}
                        paddingAngle={1}
                        radius={100}
                        startAngle={0}
                        viewBoxSize={[160, 190]}
                        label={(data) => {
                            return data.dataEntry.title;
                        }}
                        labelPosition={71}
                        labelStyle={{
                            fontSize: "3.5px",
                            color: 'white !important',
                            fontWeight: "600",
                            textAlign: 'center'
                        }}
                    />
                </div> */}

            <FooterContainer></FooterContainer>

        </div>

    )
}
